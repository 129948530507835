import qs from 'qs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Param<T> {
  name: string;
  constructor(name: string) {
    this.name = name;
  }
}

interface Storage {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

// просто набор подсказок для автокомплита и проверки типов
// tslint:disable-next-line
export class AppParams {
  // Заголовок в шапке на новой странице
  static title = new Param<string>('app_label');
  // url, на который ведет кнопка 'назад'
  static backURL = new Param<string>('app_back');
  // с каким эффектом должна открыться ссылка. на текущий момент значением всегда является 'base'
  static effect = new Param<string>('app_effect');
  // N, количество экранов на которое необходимо вернуться 'назад'
  static screensBack = new Param<number>('app_screen_back');
  // true/false, после возвращения к предыдущему экрану, необходимо обновить его содержимое
  static reloadOnBack = new Param<boolean>('app_screen_reload');
  // true/false, после возвращения к экрану, в адресе webview которого параметр установлен в true,
  // необходимо обновить его содержимое
  static reloadAlways = new Param<boolean>('app_screen_always_reload');

  // номер шага в мастере
  static stepCount = new Param<number>('app_step_count');

  // true/false, открыть ссылку в браузере
  static openInBrowser = new Param<boolean>('open_in_external_browser');

  storage: Storage = {}; // eslint-disable-line @typescript-eslint/no-explicit-any

  add<T>(param: Param<T>, value: T): AppParams {
    this.storage[param.name] = value;
    return this;
  }

  update(another: AppParams): AppParams {
    Object.assign(another.storage);
    return this;
  }

  merge(another: AppParams): AppParams {
    const result = new AppParams();
    result.storage = { ...this.storage, ...another.storage };
    return result;
  }

  stringify(): string {
    return qs.stringify(this.storage);
  }

  parse(queryString: string) {
    const parsed = qs.parse(queryString, { ignoreQueryPrefix: true });
    [
      AppParams.title,
      AppParams.backURL,
      AppParams.effect,
      AppParams.screensBack,
      AppParams.reloadOnBack,
      AppParams.reloadAlways,
      AppParams.stepCount
    ].forEach((element) => {
      if (Object.prototype.hasOwnProperty.call(parsed, element.name)) {
        this.storage[element.name] = parsed[element.name];
      }
    });
  }
}
