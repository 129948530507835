import qs from 'qs';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
class Param<T> {
  name: string;
  constructor(name: string) {
    this.name = name;
  }
}

interface Storage {
  [key: string]: any; // eslint-disable-line @typescript-eslint/no-explicit-any
}

// tslint:disable-next-line
export class LinkParams {
  // Заголовок в шапке на новой странице
  static title = new Param<string>('app_label');
  // url, на который ведет кнопка 'назад'
  static backURL = new Param<string>('app_back');
  // с каким эффектом должна открыться ссылка. на текущий момент значением всегда является 'base'
  static effect = new Param<string>('app_effect');
  // N, количество экранов на которое необходимо вернуться 'назад'
  static screensBack = new Param<number>('app_screen_back');
  // true/false, после возвращения к предыдущему экрану, необходимо обновить его содержимое
  static reloadOnBack = new Param<boolean>('app_screen_reload');
  // true/false, после возвращения к экрану, в адресе webview которого параметр установлен в true,
  // необходимо обновить его содержимое
  static reloadAlways = new Param<boolean>('app_screen_always_reload');
  // номер шага в мастере
  static stepCount = new Param<number>('app_step_count');
  // true/false, открыть ссылку в браузере
  static openInBrowser = new Param<boolean>('open_in_external_browser');

  baseLink = '';
  isApp = false;

  storage: Storage = {}; // eslint-disable-line @typescript-eslint/no-explicit-any
  appParamsStorage: Storage = {}; // eslint-disable-line @typescript-eslint/no-explicit-any

  addBaseLink(link: string) {
    this.baseLink = link;
    return this;
  }

  addAppParam<T>(param: Param<T>, value: T): LinkParams {
    this.appParamsStorage[param.name] = value;
    return this;
  }

  addParam(param: string, value: string): LinkParams {
    this.storage[param] = value;
    return this;
  }

  setIsApp(value?: boolean) {
    this.isApp = !!value;
    return this;
  }

  stringify(): string {
    let commonStorage = { ...this.storage };
    if (this.isApp) {
      commonStorage = Object.assign(commonStorage, this.appParamsStorage);
    }

    return `${this.baseLink}?${qs.stringify(commonStorage)}`;
  }
}
